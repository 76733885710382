import React from 'react';
import Layout from '../components/layout';
import styled from 'styled-components';
import colors from '../components/theme';

const TitleSection = styled.div`
display: flex; 
justify-content: center;
align-items: center;
font-family: rubik;
font-size: 40px;
font-weight: 400;
text-transform: uppercase;
background-color: ${colors.gray};
color: #fff;
height: 20vh;
`

const Body = styled.div`
display: flex;
height: 100vh;
`

const LeftNav = styled.div`
display: flex;
flex-direction: column;
align-items: center;
font-family: rubik;
width: 20%;
background-color: ${colors.pink};
box-shadow: 1px 2px 3px lightgray;
`

const PostTitleList = styled.ul`
font-weight: 200;
`

const PostListItem = styled.li`
list-style: none;
padding: 5px;

`


const LeftNavTitle = styled.div`
font-size: 35px;
font-weight: 400;
text-transform: uppercase;
padding: 15px;
`

const PostArea = styled.div`
display: flex; 
flex-direction: column; 
width: 80%;
font-family: rubik;
font-weight: 200;
align-items: center;
`

const Post = styled.div`
width: 75%;
padding: 20px;
box-shadow: 1px 2px 3px gray;
border-radius: 5px;
`

const PostTitle = styled.div`
font-size: 22px;
font-weight: 400;
margin: 20px 0px 20px 0px;

`




const Blog = (props) => {
    return (
        <Layout>
            <TitleSection>
                Blog
            </TitleSection>
            <Body>
                <LeftNav>
                    <LeftNavTitle>
                        Posts
                    </LeftNavTitle>
                    <PostTitleList>
                        <PostListItem>
                            Post #1
                        </PostListItem>
                        <PostListItem>
                            Post #2
                        </PostListItem>
                        <PostListItem>
                            Post #3
                        </PostListItem>

                    </PostTitleList>

                    Test
                </LeftNav>
                <PostArea>
                    <Post>
                    <PostTitle>Lorem Ipsum</PostTitle>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ultricies mi quis hendrerit dolor magna eget est lorem. Varius quam quisque id diam vel quam. Eget nulla facilisi etiam dignissim diam. Congue mauris rhoncus aenean vel. Id eu nisl nunc mi ipsum faucibus vitae. Tincidunt vitae semper quis lectus nulla at. Eros donec ac odio tempor orci dapibus. Egestas integer eget aliquet nibh. Leo urna molestie at elementum. Auctor elit sed vulputate mi sit amet mauris. Dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras tincidunt. Tincidunt eget nullam non nisi est sit amet. Enim nec dui nunc mattis enim. Habitant morbi tristique senectus et netus et malesuada fames ac. Enim nec dui nunc mattis enim. Risus nec feugiat in fermentum. Urna duis convallis convallis tellus id interdum velit.
                    <br/>
                    <br/>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ultricies mi quis hendrerit dolor magna eget est lorem. Varius quam quisque id diam vel quam. Eget nulla facilisi etiam dignissim diam. Congue mauris rhoncus aenean vel. Id eu nisl nunc mi ipsum faucibus vitae. Tincidunt vitae semper quis lectus nulla at. Eros donec ac odio tempor orci dapibus. Egestas integer eget aliquet nibh. Leo urna molestie at elementum. Auctor elit sed vulputate mi sit amet mauris. Dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras tincidunt. Tincidunt eget nullam non nisi est sit amet. Enim nec dui nunc mattis enim. Habitant morbi tristique senectus et netus et malesuada fames ac. Enim nec dui nunc mattis enim. Risus nec feugiat in fermentum. Urna duis convallis convallis tellus id interdum velit.
                    </Post>
                
                </PostArea>


            </Body>

        </Layout>
    )
}

export default Blog;